const util = require('./util');
const productUtil = require('./pages/product/productUtil');
const bonusProductsView = require('./bonus-products-view');

const timer = {
    id: null,
    clear() {
        if (this.id) {
            window.clearTimeout(this.id);
            delete this.id;
        }
    },
    start(duration, callback) {
        this.id = setTimeout(callback, duration);
    },
};

const minicart = {
    init() {
        this.$el = $('#mini-cart');
        this.$content = this.$el.find('.mini-cart-content');

        // events
        this.$el.find('.mini-cart-total').on('mouseenter', () => {
            if (this.$content.not(':visible')) {
                this.slide();
                $('.mini-cart-link').attr('aria-expanded', 'true');
            }
        });

        this.$content.on('mouseenter', () => {
            timer.clear();
        }).on('mouseleave', () => {
            timer.clear();
            timer.start(30, this.close.bind(this));
            $('.mini-cart-link').attr('aria-expanded', 'false');
        });
    },
    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     */
    show(html) {
        this.$el.html(html);
        this.init();
        this.slide();
        if ($('.mini-cart-content').hasClass('limit-error')) {
            util.scrollBrowser(0, () => {
                productUtil.showLimitErrorDialog();
            });
        } else {
            util.scrollBrowser(0);
        }
        $('.mini-cart-link').focus();
        this.setFocus();
    },
    /**
     * @function
     * @description Shows the given content in the add to cart dialog
     * @param {String} A HTML string with the content which will be shown
     */
    showmodal(html) {
        this.$el.html(html);
        this.openModal();
    },
    /**
     * @function
     * @description Slides down and show the contents of the mini cart
     */
    slide() {
        timer.clear();
        // show the item
        this.$content.slideDown('slow');
        // after a time out automatically close it
        timer.start(6000, this.close.bind(this));
        $('.mini-cart-link').attr('aria-expanded', 'true');
    },
    /**
     * @function
     * @description Closes the mini cart with given delay
     * @param {Number} delay The delay in milliseconds
     */
    close(delay) {
        timer.clear();
        this.$content.slideUp(delay);
        $('.mini-cart-link').attr('aria-expanded', 'false');
    },
    /**
     * @function
     * @description Sets up the focus events for the mini cart
     */
    setFocus() {
        /**
         * @listener
         * @description Set the aria-expanded value to true, show the mini cart and focus on the mini cart header when the mini cart icon is focused or moused over
         */
        $('.mini-cart-link').on('focus mouseenter', (e) => {
            $(e.currentTarget).attr('aria-expanded', 'true');

            if (!$(e.currentTarget).parent().next('.mini-cart-content').is(':visible')) {
                $(e.currentTarget).parent().next('.mini-cart-content').show();
            }

            $('.mini-cart-header').focus();
        });

        /**
         * @listener
         * @description Set the aria-expanded value to false when the mini cart is hidden
         */
        $('.mini-cart-link-checkout').on('blur', () => {
            $('.mini-cart-content').hide();
            $('.mini-cart-link').attr('aria-expanded', 'false');
        });

        /**
         * @listener
         * @description Allow the user to exit out of the mini cart via keyboard via the escape key
         */
        $('.mini-cart-link').on('keyup', (e) => {
            if (e.keyCode === 27) {
                $(e.currentTarget).attr('aria-expanded', 'false');
                $(e.currentTarget).parent().next('.mini-cart-content').hide();
            }
        });
    },
    /**
     * @function
     * @description Open Add to Cart Modal
     */
    openModal() {
        const atcModal = $('.mini-cart-content-modal');
        atcModal.dialog({
            width: 820,
            autoOpen: false,
            modal: true,
            dialogClass: 'add-to-cart-dialog',
            open() {
                // move the title html into the dialog titlebar element; can't pass in as string
                $('.ui-dialog-title').html($('.add-to-cart-dialog-title').html());
                $('.add-to-cart-dialog-title').remove();
                $('.ui-widget-overlay').addClass('atc-overlay');
                $(document).on('click', '.atc-overlay', () => {
                    try {
                        $('.ui-dialog-content').dialog().dialog('close');
                    } catch (e) {
                        // Ignore exceptions thrown if the customer rapidly clicks the overlay
                    }
                });
            },
            close() {
                // remove dialog from DOM
                atcModal.dialog('destroy').remove();
            },
        });

        atcModal.dialog('open');
        atcModal.find('.close-modal').on('click', () => {
            $('.ui-dialog-content').dialog().dialog('close');
        });
        atcModal.find('.select-bonus').on('click', (e) => {
            e.preventDefault();
            bonusProductsView.show(e.currentTarget.href);
        });
        util.initDynamicCarousel('.add-to-cart-modal-recommendations [id^="cq_recomm_slot"]', '.tiles-container', 0, 3);
    },
};

module.exports = minicart;
