/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

const qas = require('../../../int_QASc/cartridge/js/qas');
const tealium = require('../../../int_tealium/cartridge/js/tealium');
const lightbox = require('../../../int_elavon/cartridge/js/lightbox');
const countries = require('./countries');
const minicart = require('./minicart');
const modules = require('./modules');
const page = require('./page');
const rating = require('./rating');
const searchplaceholder = require('./searchplaceholder');
const tooltip = require('./tooltip');
const validator = require('./validator');
const tls = require('./tls');
const styleguide = require('./pages/styleguide');
const util = require('./util');

// eslint-disable-next-line
__webpack_public_path__ = Urls.staticPath;

require('./jquery-ext')();
require('./cookieprivacy')();
require('./captcha')();
require('picturefill');

function mobileNavInit() {
    $('.account-menu-select').on('click', () => {
        $('.account-menu-select').toggleClass('open');
        $('.account-menu').slideToggle();
    });

    util.smartResize(() => {
        if (util.mediaBreakpointUp('md')) {
            $('.account-menu').show();
        } else {
            $('.account-menu-select').removeClass('open');
            $('.account-menu').hide();
        }
    });
}

const app = {
    init() {
        if (document.cookie.length === 0) {
            $('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
        }

        // add class to html for css targeting
        $('html').addClass('js');

        // Ensure tab order is enforced if user follows internal link (i.e. 'skip-nav' link)
        $('.skip-nav').on('click', (e) => {
            const targetId = $(e.currentTarget).attr('href');
            if (!targetId) {
                return;
            }

            const target = $(targetId);
            if (target.length === 0) {
                return;
            }

            if (!/^(?:a|select|input|button|textarea)$/i.test(target.prop('nodeName'))) {
                target.attr('tabindex', '-1');
            }

            target.trigger('focus');
        });

        if (!SitePreferences.ENABLE_CONTEXT_MENU) {
            $(document).on('contextmenu', 'img', (e) => {
                e.preventDefault();
                return false;
            });
        }

        if (SitePreferences.LISTING_INFINITE_SCROLL) {
            $('html').addClass('infinite-scroll');
        }

        // Make sure that the SVGs work properly in older browsers
        svg4everybody();

        // init specific global components
        modules.init();
        countries.init();
        tooltip.init();
        minicart.init();
        validator.init();
        qas.init();
        rating.init();
        searchplaceholder.init();
        mobileNavInit();
        styleguide.init();

        // execute page specific initializations
        $.extend(page, window.pageContext);

        if (page.ns === 'account') {
            require.ensure([], (require) => {
                require('./pages/account').init();
            }, 'js/splits/account');
        } else if (page.ns === 'cart') {
            require.ensure([], (require) => {
                require('./pages/cart').init();
            }, 'js/splits/cart');
        } else if (page.ns === 'checkout') {
            require.ensure([], (require) => {
                require('./pages/checkout').init();
            }, 'js/splits/checkout');
        } else if (page.ns === 'compare') {
            require.ensure([], (require) => {
                require('./pages/compare').init();
            }, 'js/splits/compare');
        } else if (page.ns === 'downpayment') {
            require.ensure([], (require) => {
                require('./pages/downpayment').init();
            }, 'js/splits/downpayment');
        } else if (page.ns === 'product') {
            require.ensure([], (require) => {
                require('./pages/product').init();
            }, 'js/splits/product');
        } else if (page.ns === 'registry') {
            require.ensure([], (require) => {
                require('./pages/registry').init();
            }, 'js/splits/registry');
        } else if (page.ns === 'search') {
            require.ensure([], (require) => {
                require('./pages/search').init();
            }, 'js/splits/search');
        } else if (page.ns === 'storefront') {
            require.ensure([], (require) => {
                require('./pages/storefront').init();
            }, 'js/splits/storefront');
        } else if (page.ns === 'wishlist') {
            require.ensure([], (require) => {
                require('./pages/wishlist').init();
            }, 'js/splits/wishlist');
        } else if (page.ns === 'error') {
            require.ensure([], (require) => {
                require('./pages/error').init();
            }, 'js/splits/error');
        }

        // initialize Tealium analytics events
        tealium.init(page.ns);

        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) {
            tls.getUserAgent();
        }

        lightbox.init();

        // Addes uncached callout to cached product tiles
        $('div.product-tile div.product-callout:empty').replaceWith(function getTileCallout() {
            return $(this).closest('div.product-tile').next('div.product-callout');
        }).promise().done(() => {
            $('div.product-tile div.product-callout.hide').fadeIn();
        });
    },
};

// general extension functions
(function overrideFormat() {
    String.format = function format(...args) {
        let [s] = args;
        const len = args.length - 1;
        for (let i = 0; i < len; i += 1) {
            s = s.replace(new RegExp(`\\{${i}\\}`, 'gm'), args[i + 1]);
        }
        return s;
    };
}());

// initialize app
$(document).ready(() => {
    app.init();
});
